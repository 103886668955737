@use 'sass:color';
@import '../styles/cue/colors';
@import '../styles/cue/sizes';

@function extractRGB($color) {
  @return '#{red($color)} #{green($color)} #{blue($color)}';
}

:host {
  display: block;
}

body {
  --brand-100: var(--assist-brand-100);
  --brand-100_rgb: #{extractRGB($crayola_blue-100)};
  --brand-200: var(--assist-brand-200);
  --brand-20: var(--assist-brand-20);
  --brand-300: var(--assist-brand-300);
  --brand-300_rgb: #{extractRGB($crayola_blue-300)};
  --brand-400: var(--assist-brand-400);
  --brand-500-5: var(--assist-brand-500-5);
  --brand-500_rgb: #{extractRGB($crayola_blue-500)};
  --brand-500: var(--assist-brand-500);
  --brand-600: var(--assist-brand-600);
  --brand-700: var(--assist-brand-700);
  --brand-800: var(--assist-brand-800);
  --brand-900: var(--assist-brand-900);
  --brand-950: var(--assist-brand-950);

  --assist-color_primary-600: #{$primary-600};
  --assist-color_primary-500: #{$primary-500};
  --assist-color_primary-400: #{$primary-400};
  --assist-color_primary-100: #{$primary-100};

  // STATE COLORS
  --assist-color_error-900: #{$error-900};
  --assist-color_error-600: #{$error-600};
  --assist-color_error-500: #{$error-500};
  --assist-color_error-400: #{$error-400};
  --assist-color_error-100: #{$error-100};
  --assist-color_warning-500: #{$warning-500};
  --assist-color_success-900: #{$success-900};
  --assist-color_success-600: #{$success-600};
  --assist-color_success-500: #{$success-500};
  --assist-color_success-400: #{$success-400};
  --assist-color_success-100: #{$success-100};

  // NEUTRAL COLORS
  --assist-color_neutral-100: #{$neutral-100};
  --assist-color_neutral-200: #{$neutral-200};
  --assist-color_neutral-300: #{$neutral-300};
  --assist-color_neutral-400: #{$neutral-400};
  --assist-color_neutral-500: #{$neutral-500};
  --assist-color_neutral-600: #{$neutral-500};
  --assist-color_neutral-700: #{$neutral-700};
  --assist-color_neutral-900: #{$neutral-900};

  --assist-inputs-padding-bottom: 4px;
  --assist-color_common_main: #{$color_common_main};
  --assist-color_common_main_hover: #{$color_common_main_hover};
  --assist-color_common_white: #{$color_common_white};
  --assist-color_common_white_hover: #{$color_common_white_hover};
  --assist-color_common_orange: #{$color_common_orange};
  --assist-color_common_orange_10: #{lighten($color_common_orange, 10)};
  --assist-color_common_lightgray: #{$color_common_lightgray};
  --assist-color_common_black: #{$color_common_black};

  // Event colors
  --assist-color_event_hover: #{$color_event_hover};
  --assist-color_event_inactive: #{$color_event_inactive};
  --assist-color_event_available: #{$color_event_available};
  --assist-color_event_single-captive: #{$color_event_single-captive};
  --assist-color_event_single-free: #{$color_event_single-free};
  --assist-color_event_multiple-free: #{$color_event_multiple-free};
  --assist-color_event_multiple-captive: #{$color_event_multiple-captive};

  // Layout colors
  --assist-color_top-menu: #{$color_top-menu};
  --assist-color_left-menu: #{$color_left-menu};
  --assist-color_left-menu-group: #{$color_left-menu-group};
  --assist-color_second-menu: #{$color_second-menu};
  --assist-color_content: #{$color_content};
  --assist-color_map: #{$color_map};

  // Event-free colors
  --assist-color_free_100: #{$color_free_100};
  --assist-color_free_075: #{$color_free_075};
  --assist-color_free_050: #{$color_free_050};
  --assist-color_free_025: #{$color_free_025};
  --assist-color_free_001: #{$color_free_001};
  --assist-color_free_000: #{$color_free_000};
  --assist-positive: #{$positive};
  --assist-positive-hover: #{$positive-hover};
  --assist-negative: #{$negative};
  --assist-negative-hover: #{$negative-hover};

  --assist-description-gray: #{$description-gray};

  --assist-color_cancel: #{$color_cancel};

  --assist-table-border-color: #{$table-border-color};
  --assist-description-border-color: #{$description-border-color};

  //HTML elements colors
  --assist-color_button_blue: #{$color_button_blue};
  --assist-color_button_blue_hover: #{$color_button_blue_hover};
  --assist-color_button_red: #{$color_button_red};
  --assist-color_button_red_hover: #{$color_button_red_hover};

  --assist-service-color: #{$service-color};
  --assist-service-color-hover: #{$service-color-hover};

  --assist-topleftBorder: #{$topleftBorder};

  --assist-top-nav-height: #{$top-nav-height};

  --assist-page-padding: #{$page-padding};

  --assist-side-menu-width: #{$side-menu-width};
  --cue-scheduler-bottom-padding: #{$scheduler-bottom-padding};

  // TODO - replace with sass value when export is available
  --colors-menu-menu-background: #1c0f13;
  --colors-menu-menu-item-active-background: #33272b;
  --colors-menu-menu-item-active-icon: $crayola_blue-500;
  --colors-menu-menu-unactive-text: #d2cfd0;
  --spacing-grid-sm: 12px;
  --menu-item-width: 18rem;
  --menu-width: 22rem;
  --menu-offset-opened: calc(var(--menu-width) + 2rem);
  // FONT SIZES

  --assist-p-size-smaller: #{$p-size-smaller};

  --assist-color_common_main-a-15: #{$color_common_main-a-15};

  // LAYOUT
  --left-panel-width: 460px;
  --lang-dropdown-padding: 1rem;
  --lang-dropdown-width: var(--lang-dropdown-padding);

  @media screen and (max-width: 1000px) {
    --left-panel-width: 350px;
  }

  @media screen and (max-width: 768px) {
    --lang-dropdown-padding: 0;
  }
}
